/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    deleted
    createdAt
    createdByID
    createdByName
    appPassword
    channel
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      appPassword
      channel
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      appPassword
      channel
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const ByEmail = /* GraphQL */ `query ByEmail(
  $email: String!
  $deletedNameCreatedAt: ModelUserUsersByEmailCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  ByEmail(
    email: $email
    deletedNameCreatedAt: $deletedNameCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      appPassword
      channel
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<APITypes.ByEmailQueryVariables, APITypes.ByEmailQuery>;
export const ByPhoneNumber = /* GraphQL */ `query ByPhoneNumber(
  $phone_number: String!
  $deletedNameCreatedAt: ModelUserUsersByPhoneNumberCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  ByPhoneNumber(
    phone_number: $phone_number
    deletedNameCreatedAt: $deletedNameCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      appPassword
      channel
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByPhoneNumberQueryVariables,
  APITypes.ByPhoneNumberQuery
>;
export const getUserChannel = /* GraphQL */ `query GetUserChannel($id: ID!) {
  getUserChannel(id: $id) {
    id
    channel
    count
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserChannelQueryVariables,
  APITypes.GetUserChannelQuery
>;
export const listUserChannels = /* GraphQL */ `query ListUserChannels(
  $filter: ModelUserChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      channel
      count
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserChannelsQueryVariables,
  APITypes.ListUserChannelsQuery
>;
export const syncUserChannels = /* GraphQL */ `query SyncUserChannels(
  $filter: ModelUserChannelFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserChannels(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      channel
      count
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserChannelsQueryVariables,
  APITypes.SyncUserChannelsQuery
>;
export const getEmailVerification = /* GraphQL */ `query GetEmailVerification($id: ID!) {
  getEmailVerification(id: $id) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmailVerificationQueryVariables,
  APITypes.GetEmailVerificationQuery
>;
export const listEmailVerifications = /* GraphQL */ `query ListEmailVerifications(
  $filter: ModelEmailVerificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmailVerifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmailVerificationsQueryVariables,
  APITypes.ListEmailVerificationsQuery
>;
export const syncEmailVerifications = /* GraphQL */ `query SyncEmailVerifications(
  $filter: ModelEmailVerificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEmailVerifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEmailVerificationsQueryVariables,
  APITypes.SyncEmailVerificationsQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    accountID
    conceptID
    bookingID
    guestId
    guestName
    guestMobile
    tableTimeSlot
    TableName
    value
    reason
    status
    rating
    groupId
    comment
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      bookingID
      guestId
      guestName
      guestMobile
      tableTimeSlot
      TableName
      value
      reason
      status
      rating
      groupId
      comment
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const syncReviews = /* GraphQL */ `query SyncReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      conceptID
      bookingID
      guestId
      guestName
      guestMobile
      tableTimeSlot
      TableName
      value
      reason
      status
      rating
      groupId
      comment
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReviewsQueryVariables,
  APITypes.SyncReviewsQuery
>;
export const reviewsByConceptID = /* GraphQL */ `query ReviewsByConceptID(
  $conceptID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByConceptID(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      conceptID
      bookingID
      guestId
      guestName
      guestMobile
      tableTimeSlot
      TableName
      value
      reason
      status
      rating
      groupId
      comment
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByConceptIDQueryVariables,
  APITypes.ReviewsByConceptIDQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    sponsorImage
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      sponsorImage
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountLogoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const syncAccounts = /* GraphQL */ `query SyncAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAccounts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      sponsorImage
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountLogoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAccountsQueryVariables,
  APITypes.SyncAccountsQuery
>;
export const getSocialLink = /* GraphQL */ `query GetSocialLink($id: ID!) {
  getSocialLink(id: $id) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialLinkQueryVariables,
  APITypes.GetSocialLinkQuery
>;
export const listSocialLinks = /* GraphQL */ `query ListSocialLinks(
  $filter: ModelSocialLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listSocialLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSocialLinksQueryVariables,
  APITypes.ListSocialLinksQuery
>;
export const syncSocialLinks = /* GraphQL */ `query SyncSocialLinks(
  $filter: ModelSocialLinkFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSocialLinks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSocialLinksQueryVariables,
  APITypes.SyncSocialLinksQuery
>;
export const getFeature = /* GraphQL */ `query GetFeature($id: ID!) {
  getFeature(id: $id) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeatureQueryVariables,
  APITypes.GetFeatureQuery
>;
export const listFeatures = /* GraphQL */ `query ListFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeaturesQueryVariables,
  APITypes.ListFeaturesQuery
>;
export const syncFeatures = /* GraphQL */ `query SyncFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFeatures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFeaturesQueryVariables,
  APITypes.SyncFeaturesQuery
>;
export const getLanguage = /* GraphQL */ `query GetLanguage($id: ID!) {
  getLanguage(id: $id) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLanguageQueryVariables,
  APITypes.GetLanguageQuery
>;
export const listLanguages = /* GraphQL */ `query ListLanguages(
  $filter: ModelLanguageFilterInput
  $limit: Int
  $nextToken: String
) {
  listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLanguagesQueryVariables,
  APITypes.ListLanguagesQuery
>;
export const syncLanguages = /* GraphQL */ `query SyncLanguages(
  $filter: ModelLanguageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLanguages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLanguagesQueryVariables,
  APITypes.SyncLanguagesQuery
>;
export const getMedia = /* GraphQL */ `query GetMedia($id: ID!) {
  getMedia(id: $id) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMediaQueryVariables, APITypes.GetMediaQuery>;
export const listMedia = /* GraphQL */ `query ListMedia(
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMediaQueryVariables, APITypes.ListMediaQuery>;
export const syncMedia = /* GraphQL */ `query SyncMedia(
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMedia(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncMediaQueryVariables, APITypes.SyncMediaQuery>;
export const getAttachment = /* GraphQL */ `query GetAttachment($id: ID!) {
  getAttachment(id: $id) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttachmentQueryVariables,
  APITypes.GetAttachmentQuery
>;
export const listAttachments = /* GraphQL */ `query ListAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttachmentsQueryVariables,
  APITypes.ListAttachmentsQuery
>;
export const syncAttachments = /* GraphQL */ `query SyncAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAttachments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAttachmentsQueryVariables,
  APITypes.SyncAttachmentsQuery
>;
export const getConcept = /* GraphQL */ `query GetConcept($id: ID!) {
  getConcept(id: $id) {
    id
    accountID
    name
    email
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    precedence
    isPinRequired
    socialMedia
    operating
    longitude
    latitude
    eventDeposit
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConceptQueryVariables,
  APITypes.GetConceptQuery
>;
export const listConcepts = /* GraphQL */ `query ListConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      email
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      precedence
      isPinRequired
      socialMedia
      operating
      longitude
      latitude
      eventDeposit
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConceptsQueryVariables,
  APITypes.ListConceptsQuery
>;
export const syncConcepts = /* GraphQL */ `query SyncConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      email
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      precedence
      isPinRequired
      socialMedia
      operating
      longitude
      latitude
      eventDeposit
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConceptsQueryVariables,
  APITypes.SyncConceptsQuery
>;
export const getParentConcept = /* GraphQL */ `query GetParentConcept($id: ID!) {
  getParentConcept(id: $id) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetParentConceptQueryVariables,
  APITypes.GetParentConceptQuery
>;
export const listParentConcepts = /* GraphQL */ `query ListParentConcepts(
  $filter: ModelParentConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listParentConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParentConceptsQueryVariables,
  APITypes.ListParentConceptsQuery
>;
export const syncParentConcepts = /* GraphQL */ `query SyncParentConcepts(
  $filter: ModelParentConceptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncParentConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncParentConceptsQueryVariables,
  APITypes.SyncParentConceptsQuery
>;
export const getGroup = /* GraphQL */ `query GetGroup($id: ID!) {
  getGroup(id: $id) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const listGroups = /* GraphQL */ `query ListGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsQueryVariables,
  APITypes.ListGroupsQuery
>;
export const syncGroups = /* GraphQL */ `query SyncGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupsQueryVariables,
  APITypes.SyncGroupsQuery
>;
export const getGroupSetting = /* GraphQL */ `query GetGroupSetting($id: ID!) {
  getGroupSetting(id: $id) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupSettingQueryVariables,
  APITypes.GetGroupSettingQuery
>;
export const listGroupSettings = /* GraphQL */ `query ListGroupSettings(
  $filter: ModelGroupSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroupSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupSettingsQueryVariables,
  APITypes.ListGroupSettingsQuery
>;
export const syncGroupSettings = /* GraphQL */ `query SyncGroupSettings(
  $filter: ModelGroupSettingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroupSettings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupSettingsQueryVariables,
  APITypes.SyncGroupSettingsQuery
>;
export const getCustomerStats = /* GraphQL */ `query GetCustomerStats($id: ID!) {
  getCustomerStats(id: $id) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerStatsQueryVariables,
  APITypes.GetCustomerStatsQuery
>;
export const listCustomerStats = /* GraphQL */ `query ListCustomerStats(
  $filter: ModelCustomerStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomerStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomerStatsQueryVariables,
  APITypes.ListCustomerStatsQuery
>;
export const syncCustomerStats = /* GraphQL */ `query SyncCustomerStats(
  $filter: ModelCustomerStatsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCustomerStats(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCustomerStatsQueryVariables,
  APITypes.SyncCustomerStatsQuery
>;
export const getFlag = /* GraphQL */ `query GetFlag($id: ID!) {
  getFlag(id: $id) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFlagQueryVariables, APITypes.GetFlagQuery>;
export const listFlags = /* GraphQL */ `query ListFlags(
  $filter: ModelFlagFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFlagsQueryVariables, APITypes.ListFlagsQuery>;
export const syncFlags = /* GraphQL */ `query SyncFlags(
  $filter: ModelFlagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFlags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncFlagsQueryVariables, APITypes.SyncFlagsQuery>;
export const getInterest = /* GraphQL */ `query GetInterest($id: ID!) {
  getInterest(id: $id) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInterestQueryVariables,
  APITypes.GetInterestQuery
>;
export const listInterests = /* GraphQL */ `query ListInterests(
  $filter: ModelInterestFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInterestsQueryVariables,
  APITypes.ListInterestsQuery
>;
export const syncInterests = /* GraphQL */ `query SyncInterests(
  $filter: ModelInterestFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInterests(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInterestsQueryVariables,
  APITypes.SyncInterestsQuery
>;
export const getReservationStatus = /* GraphQL */ `query GetReservationStatus($id: ID!) {
  getReservationStatus(id: $id) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationStatusQueryVariables,
  APITypes.GetReservationStatusQuery
>;
export const listReservationStatuses = /* GraphQL */ `query ListReservationStatuses(
  $filter: ModelReservationStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      name
      category
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationStatusesQueryVariables,
  APITypes.ListReservationStatusesQuery
>;
export const syncReservationStatuses = /* GraphQL */ `query SyncReservationStatuses(
  $filter: ModelReservationStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReservationStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      category
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReservationStatusesQueryVariables,
  APITypes.SyncReservationStatusesQuery
>;
export const getTimeSlot = /* GraphQL */ `query GetTimeSlot($id: ID!) {
  getTimeSlot(id: $id) {
    id
    conceptID
    name
    friendlyName
    bookings
    events
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimeSlotQueryVariables,
  APITypes.GetTimeSlotQuery
>;
export const listTimeSlots = /* GraphQL */ `query ListTimeSlots(
  $filter: ModelTimeSlotFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimeSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      friendlyName
      bookings
      events
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimeSlotsQueryVariables,
  APITypes.ListTimeSlotsQuery
>;
export const syncTimeSlots = /* GraphQL */ `query SyncTimeSlots(
  $filter: ModelTimeSlotFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTimeSlots(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      friendlyName
      bookings
      events
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTimeSlotsQueryVariables,
  APITypes.SyncTimeSlotsQuery
>;
export const getArea = /* GraphQL */ `query GetArea($id: ID!) {
  getArea(id: $id) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAreaQueryVariables, APITypes.GetAreaQuery>;
export const listAreas = /* GraphQL */ `query ListAreas(
  $filter: ModelAreaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAreasQueryVariables, APITypes.ListAreasQuery>;
export const syncAreas = /* GraphQL */ `query SyncAreas(
  $filter: ModelAreaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAreas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncAreasQueryVariables, APITypes.SyncAreasQuery>;
export const getPlanItem = /* GraphQL */ `query GetPlanItem($id: ID!) {
  getPlanItem(id: $id) {
    id
    areaID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlanItemQueryVariables,
  APITypes.GetPlanItemQuery
>;
export const listPlanItems = /* GraphQL */ `query ListPlanItems(
  $filter: ModelPlanItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlanItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      areaID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlanItemsQueryVariables,
  APITypes.ListPlanItemsQuery
>;
export const syncPlanItems = /* GraphQL */ `query SyncPlanItems(
  $filter: ModelPlanItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPlanItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      areaID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPlanItemsQueryVariables,
  APITypes.SyncPlanItemsQuery
>;
export const getTable = /* GraphQL */ `query GetTable($id: ID!) {
  getTable(id: $id) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTableQueryVariables, APITypes.GetTableQuery>;
export const listTables = /* GraphQL */ `query ListTables(
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  listTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTablesQueryVariables,
  APITypes.ListTablesQuery
>;
export const syncTables = /* GraphQL */ `query SyncTables(
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTables(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTablesQueryVariables,
  APITypes.SyncTablesQuery
>;
export const getObject = /* GraphQL */ `query GetObject($id: ID!) {
  getObject(id: $id) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetObjectQueryVariables, APITypes.GetObjectQuery>;
export const listObjects = /* GraphQL */ `query ListObjects(
  $filter: ModelObjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      label
      name
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListObjectsQueryVariables,
  APITypes.ListObjectsQuery
>;
export const syncObjects = /* GraphQL */ `query SyncObjects(
  $filter: ModelObjectFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncObjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      label
      name
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncObjectsQueryVariables,
  APITypes.SyncObjectsQuery
>;
export const getFriend = /* GraphQL */ `query GetFriend($id: ID!) {
  getFriend(id: $id) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFriendQueryVariables, APITypes.GetFriendQuery>;
export const listFriends = /* GraphQL */ `query ListFriends(
  $filter: ModelFriendFilterInput
  $limit: Int
  $nextToken: String
) {
  listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFriendsQueryVariables,
  APITypes.ListFriendsQuery
>;
export const syncFriends = /* GraphQL */ `query SyncFriends(
  $filter: ModelFriendFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFriends(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFriendsQueryVariables,
  APITypes.SyncFriendsQuery
>;
export const getBooking = /* GraphQL */ `query GetBooking($id: ID!) {
  getBooking(id: $id) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    customerGroupColor
    customerGroupID
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingQueryVariables,
  APITypes.GetBookingQuery
>;
export const listBookings = /* GraphQL */ `query ListBookings(
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      customerGroupColor
      customerGroupID
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingsQueryVariables,
  APITypes.ListBookingsQuery
>;
export const syncBookings = /* GraphQL */ `query SyncBookings(
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBookings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      customerGroupColor
      customerGroupID
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBookingsQueryVariables,
  APITypes.SyncBookingsQuery
>;
export const bookingByConcept = /* GraphQL */ `query BookingByConcept(
  $conceptID: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingByConcept(
    conceptID: $conceptID
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      customerGroupColor
      customerGroupID
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingByConceptQueryVariables,
  APITypes.BookingByConceptQuery
>;
export const BookingMainGuest = /* GraphQL */ `query BookingMainGuest(
  $mainGuest: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  BookingMainGuest(
    mainGuest: $mainGuest
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      customerGroupColor
      customerGroupID
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingMainGuestQueryVariables,
  APITypes.BookingMainGuestQuery
>;
export const BookingDate = /* GraphQL */ `query BookingDate(
  $date: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  BookingDate(
    date: $date
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      customerGroupColor
      customerGroupID
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingDateQueryVariables,
  APITypes.BookingDateQuery
>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const syncComments = /* GraphQL */ `query SyncComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncComments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommentsQueryVariables,
  APITypes.SyncCommentsQuery
>;
export const getTimeline = /* GraphQL */ `query GetTimeline($id: ID!) {
  getTimeline(id: $id) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimelineQueryVariables,
  APITypes.GetTimelineQuery
>;
export const listTimelines = /* GraphQL */ `query ListTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimelinesQueryVariables,
  APITypes.ListTimelinesQuery
>;
export const syncTimelines = /* GraphQL */ `query SyncTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTimelines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTimelinesQueryVariables,
  APITypes.SyncTimelinesQuery
>;
export const getServingArea = /* GraphQL */ `query GetServingArea($id: ID!) {
  getServingArea(id: $id) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServingAreaQueryVariables,
  APITypes.GetServingAreaQuery
>;
export const listServingAreas = /* GraphQL */ `query ListServingAreas(
  $filter: ModelServingAreaFilterInput
  $limit: Int
  $nextToken: String
) {
  listServingAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServingAreasQueryVariables,
  APITypes.ListServingAreasQuery
>;
export const syncServingAreas = /* GraphQL */ `query SyncServingAreas(
  $filter: ModelServingAreaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncServingAreas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncServingAreasQueryVariables,
  APITypes.SyncServingAreasQuery
>;
export const getCall = /* GraphQL */ `query GetCall($id: ID!) {
  getCall(id: $id) {
    id
    phone_number
    customer
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCallQueryVariables, APITypes.GetCallQuery>;
export const listCalls = /* GraphQL */ `query ListCalls(
  $filter: ModelCallFilterInput
  $limit: Int
  $nextToken: String
) {
  listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phone_number
      customer
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCallsQueryVariables, APITypes.ListCallsQuery>;
export const syncCalls = /* GraphQL */ `query SyncCalls(
  $filter: ModelCallFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCalls(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      phone_number
      customer
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncCallsQueryVariables, APITypes.SyncCallsQuery>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const syncNotifications = /* GraphQL */ `query SyncNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncNotificationsQueryVariables,
  APITypes.SyncNotificationsQuery
>;
export const getAdminRole = /* GraphQL */ `query GetAdminRole($id: ID!) {
  getAdminRole(id: $id) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminRoleQueryVariables,
  APITypes.GetAdminRoleQuery
>;
export const listAdminRoles = /* GraphQL */ `query ListAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminRolesQueryVariables,
  APITypes.ListAdminRolesQuery
>;
export const syncAdminRoles = /* GraphQL */ `query SyncAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminRolesQueryVariables,
  APITypes.SyncAdminRolesQuery
>;
export const getAdminGroup = /* GraphQL */ `query GetAdminGroup($id: ID!) {
  getAdminGroup(id: $id) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminGroupQueryVariables,
  APITypes.GetAdminGroupQuery
>;
export const listAdminGroups = /* GraphQL */ `query ListAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminGroupsQueryVariables,
  APITypes.ListAdminGroupsQuery
>;
export const syncAdminGroups = /* GraphQL */ `query SyncAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminGroupsQueryVariables,
  APITypes.SyncAdminGroupsQuery
>;
export const getUserConcepts = /* GraphQL */ `query GetUserConcepts($id: ID!) {
  getUserConcepts(id: $id) {
    id
    defaultConcept
    concepts
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserConceptsQueryVariables,
  APITypes.GetUserConceptsQuery
>;
export const listUserConcepts = /* GraphQL */ `query ListUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      defaultConcept
      concepts
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserConceptsQueryVariables,
  APITypes.ListUserConceptsQuery
>;
export const syncUserConcepts = /* GraphQL */ `query SyncUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      defaultConcept
      concepts
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserConceptsQueryVariables,
  APITypes.SyncUserConceptsQuery
>;
export const getTransaction = /* GraphQL */ `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionQueryVariables,
  APITypes.GetTransactionQuery
>;
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsQueryVariables,
  APITypes.ListTransactionsQuery
>;
export const syncTransactions = /* GraphQL */ `query SyncTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTransactionsQueryVariables,
  APITypes.SyncTransactionsQuery
>;
export const bookingTransactions = /* GraphQL */ `query BookingTransactions(
  $bookingID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingTransactions(
    bookingID: $bookingID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingTransactionsQueryVariables,
  APITypes.BookingTransactionsQuery
>;
export const getPaymentStat = /* GraphQL */ `query GetPaymentStat($id: ID!) {
  getPaymentStat(id: $id) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentStatQueryVariables,
  APITypes.GetPaymentStatQuery
>;
export const listPaymentStats = /* GraphQL */ `query ListPaymentStats(
  $filter: ModelPaymentStatFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentStatsQueryVariables,
  APITypes.ListPaymentStatsQuery
>;
export const syncPaymentStats = /* GraphQL */ `query SyncPaymentStats(
  $filter: ModelPaymentStatFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPaymentStats(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPaymentStatsQueryVariables,
  APITypes.SyncPaymentStatsQuery
>;
export const getBookingGuest = /* GraphQL */ `query GetBookingGuest($id: ID!) {
  getBookingGuest(id: $id) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingGuestQueryVariables,
  APITypes.GetBookingGuestQuery
>;
export const listBookingGuests = /* GraphQL */ `query ListBookingGuests(
  $filter: ModelBookingGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookingGuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingGuestsQueryVariables,
  APITypes.ListBookingGuestsQuery
>;
export const syncBookingGuests = /* GraphQL */ `query SyncBookingGuests(
  $filter: ModelBookingGuestFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBookingGuests(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBookingGuestsQueryVariables,
  APITypes.SyncBookingGuestsQuery
>;
export const guestByBookingID = /* GraphQL */ `query GuestByBookingID(
  $bookingID: String!
  $guestName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  guestByBookingID(
    bookingID: $bookingID
    guestName: $guestName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GuestByBookingIDQueryVariables,
  APITypes.GuestByBookingIDQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    conceptID
    preparationAreaID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      preparationAreaID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const syncCategories = /* GraphQL */ `query SyncCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      preparationAreaID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCategoriesQueryVariables,
  APITypes.SyncCategoriesQuery
>;
export const getMenuItem = /* GraphQL */ `query GetMenuItem($id: ID!) {
  getMenuItem(id: $id) {
    id
    conceptID
    kioskID
    name
    description
    image
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    ratings
    prices
    choiceGroups
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemQueryVariables,
  APITypes.GetMenuItemQuery
>;
export const listMenuItems = /* GraphQL */ `query ListMenuItems(
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      kioskID
      name
      description
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuItemsQueryVariables,
  APITypes.ListMenuItemsQuery
>;
export const syncMenuItems = /* GraphQL */ `query SyncMenuItems(
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMenuItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      kioskID
      name
      description
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMenuItemsQueryVariables,
  APITypes.SyncMenuItemsQuery
>;
export const getPrice = /* GraphQL */ `query GetPrice($id: ID!) {
  getPrice(id: $id) {
    id
    conceptID
    name
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPriceQueryVariables, APITypes.GetPriceQuery>;
export const listPrices = /* GraphQL */ `query ListPrices(
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPricesQueryVariables,
  APITypes.ListPricesQuery
>;
export const syncPrices = /* GraphQL */ `query SyncPrices(
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPricesQueryVariables,
  APITypes.SyncPricesQuery
>;
export const getChoiceGroups = /* GraphQL */ `query GetChoiceGroups($id: ID!) {
  getChoiceGroups(id: $id) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChoiceGroupsQueryVariables,
  APITypes.GetChoiceGroupsQuery
>;
export const listChoiceGroups = /* GraphQL */ `query ListChoiceGroups(
  $filter: ModelChoiceGroupsFilterInput
  $limit: Int
  $nextToken: String
) {
  listChoiceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChoiceGroupsQueryVariables,
  APITypes.ListChoiceGroupsQuery
>;
export const syncChoiceGroups = /* GraphQL */ `query SyncChoiceGroups(
  $filter: ModelChoiceGroupsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChoiceGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChoiceGroupsQueryVariables,
  APITypes.SyncChoiceGroupsQuery
>;
export const getChoice = /* GraphQL */ `query GetChoice($id: ID!) {
  getChoice(id: $id) {
    id
    conceptID
    name
    price
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetChoiceQueryVariables, APITypes.GetChoiceQuery>;
export const listChoices = /* GraphQL */ `query ListChoices(
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      price
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChoicesQueryVariables,
  APITypes.ListChoicesQuery
>;
export const syncChoices = /* GraphQL */ `query SyncChoices(
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChoices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      price
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChoicesQueryVariables,
  APITypes.SyncChoicesQuery
>;
export const getCart = /* GraphQL */ `query GetCart($id: ID!) {
  getCart(id: $id) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    specialRequest
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCartQueryVariables, APITypes.GetCartQuery>;
export const listCarts = /* GraphQL */ `query ListCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      userID
      specialRequest
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCartsQueryVariables, APITypes.ListCartsQuery>;
export const syncCarts = /* GraphQL */ `query SyncCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCarts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      userID
      specialRequest
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncCartsQueryVariables, APITypes.SyncCartsQuery>;
export const getAdminStatus = /* GraphQL */ `query GetAdminStatus($id: ID!) {
  getAdminStatus(id: $id) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminStatusQueryVariables,
  APITypes.GetAdminStatusQuery
>;
export const listAdminStatuses = /* GraphQL */ `query ListAdminStatuses(
  $filter: ModelAdminStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminStatusesQueryVariables,
  APITypes.ListAdminStatusesQuery
>;
export const syncAdminStatuses = /* GraphQL */ `query SyncAdminStatuses(
  $filter: ModelAdminStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminStatusesQueryVariables,
  APITypes.SyncAdminStatusesQuery
>;
export const getOnlineOrder = /* GraphQL */ `query GetOnlineOrder($id: ID!) {
  getOnlineOrder(id: $id) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    userID
    userName
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    deliveryFee
    orderTime
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    waiterID
    tableID
    shift
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    deliveredAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOnlineOrderQueryVariables,
  APITypes.GetOnlineOrderQuery
>;
export const listOnlineOrders = /* GraphQL */ `query ListOnlineOrders(
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOnlineOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      userID
      userName
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      deliveryFee
      orderTime
      specialRequest
      orderAddress
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      waiterID
      tableID
      shift
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      deliveredAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOnlineOrdersQueryVariables,
  APITypes.ListOnlineOrdersQuery
>;
export const syncOnlineOrders = /* GraphQL */ `query SyncOnlineOrders(
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOnlineOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      userID
      userName
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      deliveryFee
      orderTime
      specialRequest
      orderAddress
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      waiterID
      tableID
      shift
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      deliveredAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOnlineOrdersQueryVariables,
  APITypes.SyncOnlineOrdersQuery
>;
export const getOrderStatus = /* GraphQL */ `query GetOrderStatus($id: ID!) {
  getOrderStatus(id: $id) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderStatusQueryVariables,
  APITypes.GetOrderStatusQuery
>;
export const listOrderStatuses = /* GraphQL */ `query ListOrderStatuses(
  $filter: ModelOrderStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderStatusesQueryVariables,
  APITypes.ListOrderStatusesQuery
>;
export const syncOrderStatuses = /* GraphQL */ `query SyncOrderStatuses(
  $filter: ModelOrderStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrderStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrderStatusesQueryVariables,
  APITypes.SyncOrderStatusesQuery
>;
export const getDiscountCoupon = /* GraphQL */ `query GetDiscountCoupon($id: ID!) {
  getDiscountCoupon(id: $id) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountCouponQueryVariables,
  APITypes.GetDiscountCouponQuery
>;
export const listDiscountCoupons = /* GraphQL */ `query ListDiscountCoupons(
  $filter: ModelDiscountCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiscountCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscountCouponsQueryVariables,
  APITypes.ListDiscountCouponsQuery
>;
export const syncDiscountCoupons = /* GraphQL */ `query SyncDiscountCoupons(
  $filter: ModelDiscountCouponFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDiscountCoupons(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDiscountCouponsQueryVariables,
  APITypes.SyncDiscountCouponsQuery
>;
export const getMobileNotification = /* GraphQL */ `query GetMobileNotification($id: ID!) {
  getMobileNotification(id: $id) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    eventDate
    conceptID
    depositValue
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMobileNotificationQueryVariables,
  APITypes.GetMobileNotificationQuery
>;
export const listMobileNotifications = /* GraphQL */ `query ListMobileNotifications(
  $filter: ModelMobileNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listMobileNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      eventDate
      conceptID
      depositValue
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMobileNotificationsQueryVariables,
  APITypes.ListMobileNotificationsQuery
>;
export const syncMobileNotifications = /* GraphQL */ `query SyncMobileNotifications(
  $filter: ModelMobileNotificationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMobileNotifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      eventDate
      conceptID
      depositValue
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMobileNotificationsQueryVariables,
  APITypes.SyncMobileNotificationsQuery
>;
export const getUserPushToken = /* GraphQL */ `query GetUserPushToken($id: ID!) {
  getUserPushToken(id: $id) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPushTokenQueryVariables,
  APITypes.GetUserPushTokenQuery
>;
export const listUserPushTokens = /* GraphQL */ `query ListUserPushTokens(
  $filter: ModelUserPushTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPushTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPushTokensQueryVariables,
  APITypes.ListUserPushTokensQuery
>;
export const syncUserPushTokens = /* GraphQL */ `query SyncUserPushTokens(
  $filter: ModelUserPushTokenFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserPushTokens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserPushTokensQueryVariables,
  APITypes.SyncUserPushTokensQuery
>;
export const getNotificationReceiver = /* GraphQL */ `query GetNotificationReceiver($id: ID!) {
  getNotificationReceiver(id: $id) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationReceiverQueryVariables,
  APITypes.GetNotificationReceiverQuery
>;
export const listNotificationReceivers = /* GraphQL */ `query ListNotificationReceivers(
  $filter: ModelNotificationReceiverFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationReceivers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationReceiversQueryVariables,
  APITypes.ListNotificationReceiversQuery
>;
export const syncNotificationReceivers = /* GraphQL */ `query SyncNotificationReceivers(
  $filter: ModelNotificationReceiverFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncNotificationReceivers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncNotificationReceiversQueryVariables,
  APITypes.SyncNotificationReceiversQuery
>;
export const notificationsByUser = /* GraphQL */ `query NotificationsByUser(
  $userID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationReceiverFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByUser(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByUserQueryVariables,
  APITypes.NotificationsByUserQuery
>;
export const getZone = /* GraphQL */ `query GetZone($id: ID!) {
  getZone(id: $id) {
    id
    conceptID
    location
    deliveryFee
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetZoneQueryVariables, APITypes.GetZoneQuery>;
export const listZones = /* GraphQL */ `query ListZones(
  $filter: ModelZoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      location
      deliveryFee
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListZonesQueryVariables, APITypes.ListZonesQuery>;
export const syncZones = /* GraphQL */ `query SyncZones(
  $filter: ModelZoneFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncZones(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      location
      deliveryFee
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncZonesQueryVariables, APITypes.SyncZonesQuery>;
export const getUserIP = /* GraphQL */ `query GetUserIP($id: ID!) {
  getUserIP(id: $id) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserIPQueryVariables, APITypes.GetUserIPQuery>;
export const listUserIPS = /* GraphQL */ `query ListUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserIPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserIPSQueryVariables,
  APITypes.ListUserIPSQuery
>;
export const syncUserIPS = /* GraphQL */ `query SyncUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserIPS(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserIPSQueryVariables,
  APITypes.SyncUserIPSQuery
>;
export const getMainCategory = /* GraphQL */ `query GetMainCategory($id: ID!) {
  getMainCategory(id: $id) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMainCategoryQueryVariables,
  APITypes.GetMainCategoryQuery
>;
export const listMainCategories = /* GraphQL */ `query ListMainCategories(
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMainCategoriesQueryVariables,
  APITypes.ListMainCategoriesQuery
>;
export const syncMainCategories = /* GraphQL */ `query SyncMainCategories(
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMainCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMainCategoriesQueryVariables,
  APITypes.SyncMainCategoriesQuery
>;
export const getShift = /* GraphQL */ `query GetShift($id: ID!) {
  getShift(id: $id) {
    id
    conceptID
    name
    precedence
    startTime
    endTime
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetShiftQueryVariables, APITypes.GetShiftQuery>;
export const listShifts = /* GraphQL */ `query ListShifts(
  $filter: ModelShiftFilterInput
  $limit: Int
  $nextToken: String
) {
  listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      precedence
      startTime
      endTime
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShiftsQueryVariables,
  APITypes.ListShiftsQuery
>;
export const syncShifts = /* GraphQL */ `query SyncShifts(
  $filter: ModelShiftFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncShifts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      precedence
      startTime
      endTime
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncShiftsQueryVariables,
  APITypes.SyncShiftsQuery
>;
export const getPreparationArea = /* GraphQL */ `query GetPreparationArea($id: ID!) {
  getPreparationArea(id: $id) {
    id
    conceptID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreparationAreaQueryVariables,
  APITypes.GetPreparationAreaQuery
>;
export const listPreparationAreas = /* GraphQL */ `query ListPreparationAreas(
  $filter: ModelPreparationAreaFilterInput
  $limit: Int
  $nextToken: String
) {
  listPreparationAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPreparationAreasQueryVariables,
  APITypes.ListPreparationAreasQuery
>;
export const syncPreparationAreas = /* GraphQL */ `query SyncPreparationAreas(
  $filter: ModelPreparationAreaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPreparationAreas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPreparationAreasQueryVariables,
  APITypes.SyncPreparationAreasQuery
>;
