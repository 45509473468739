/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    deleted
    createdAt
    createdByID
    createdByName
    appPassword
    channel
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    deleted
    createdAt
    createdByID
    createdByName
    appPassword
    channel
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    deleted
    createdAt
    createdByID
    createdByName
    appPassword
    channel
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserChannel = /* GraphQL */ `mutation CreateUserChannel(
  $input: CreateUserChannelInput!
  $condition: ModelUserChannelConditionInput
) {
  createUserChannel(input: $input, condition: $condition) {
    id
    channel
    count
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserChannelMutationVariables,
  APITypes.CreateUserChannelMutation
>;
export const updateUserChannel = /* GraphQL */ `mutation UpdateUserChannel(
  $input: UpdateUserChannelInput!
  $condition: ModelUserChannelConditionInput
) {
  updateUserChannel(input: $input, condition: $condition) {
    id
    channel
    count
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserChannelMutationVariables,
  APITypes.UpdateUserChannelMutation
>;
export const deleteUserChannel = /* GraphQL */ `mutation DeleteUserChannel(
  $input: DeleteUserChannelInput!
  $condition: ModelUserChannelConditionInput
) {
  deleteUserChannel(input: $input, condition: $condition) {
    id
    channel
    count
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserChannelMutationVariables,
  APITypes.DeleteUserChannelMutation
>;
export const createEmailVerification = /* GraphQL */ `mutation CreateEmailVerification(
  $input: CreateEmailVerificationInput!
  $condition: ModelEmailVerificationConditionInput
) {
  createEmailVerification(input: $input, condition: $condition) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmailVerificationMutationVariables,
  APITypes.CreateEmailVerificationMutation
>;
export const updateEmailVerification = /* GraphQL */ `mutation UpdateEmailVerification(
  $input: UpdateEmailVerificationInput!
  $condition: ModelEmailVerificationConditionInput
) {
  updateEmailVerification(input: $input, condition: $condition) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailVerificationMutationVariables,
  APITypes.UpdateEmailVerificationMutation
>;
export const deleteEmailVerification = /* GraphQL */ `mutation DeleteEmailVerification(
  $input: DeleteEmailVerificationInput!
  $condition: ModelEmailVerificationConditionInput
) {
  deleteEmailVerification(input: $input, condition: $condition) {
    id
    accountID
    name
    email
    verification_code
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmailVerificationMutationVariables,
  APITypes.DeleteEmailVerificationMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    bookingID
    guestId
    guestName
    guestMobile
    tableTimeSlot
    TableName
    value
    reason
    status
    rating
    groupId
    comment
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    bookingID
    guestId
    guestName
    guestMobile
    tableTimeSlot
    TableName
    value
    reason
    status
    rating
    groupId
    comment
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    bookingID
    guestId
    guestName
    guestMobile
    tableTimeSlot
    TableName
    value
    reason
    status
    rating
    groupId
    comment
    createdAt
    deleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    sponsorImage
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    sponsorImage
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    sponsorImage
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createSocialLink = /* GraphQL */ `mutation CreateSocialLink(
  $input: CreateSocialLinkInput!
  $condition: ModelSocialLinkConditionInput
) {
  createSocialLink(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSocialLinkMutationVariables,
  APITypes.CreateSocialLinkMutation
>;
export const updateSocialLink = /* GraphQL */ `mutation UpdateSocialLink(
  $input: UpdateSocialLinkInput!
  $condition: ModelSocialLinkConditionInput
) {
  updateSocialLink(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSocialLinkMutationVariables,
  APITypes.UpdateSocialLinkMutation
>;
export const deleteSocialLink = /* GraphQL */ `mutation DeleteSocialLink(
  $input: DeleteSocialLinkInput!
  $condition: ModelSocialLinkConditionInput
) {
  deleteSocialLink(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSocialLinkMutationVariables,
  APITypes.DeleteSocialLinkMutation
>;
export const createFeature = /* GraphQL */ `mutation CreateFeature(
  $input: CreateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  createFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeatureMutationVariables,
  APITypes.CreateFeatureMutation
>;
export const updateFeature = /* GraphQL */ `mutation UpdateFeature(
  $input: UpdateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  updateFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeatureMutationVariables,
  APITypes.UpdateFeatureMutation
>;
export const deleteFeature = /* GraphQL */ `mutation DeleteFeature(
  $input: DeleteFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  deleteFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeatureMutationVariables,
  APITypes.DeleteFeatureMutation
>;
export const createLanguage = /* GraphQL */ `mutation CreateLanguage(
  $input: CreateLanguageInput!
  $condition: ModelLanguageConditionInput
) {
  createLanguage(input: $input, condition: $condition) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLanguageMutationVariables,
  APITypes.CreateLanguageMutation
>;
export const updateLanguage = /* GraphQL */ `mutation UpdateLanguage(
  $input: UpdateLanguageInput!
  $condition: ModelLanguageConditionInput
) {
  updateLanguage(input: $input, condition: $condition) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLanguageMutationVariables,
  APITypes.UpdateLanguageMutation
>;
export const deleteLanguage = /* GraphQL */ `mutation DeleteLanguage(
  $input: DeleteLanguageInput!
  $condition: ModelLanguageConditionInput
) {
  deleteLanguage(input: $input, condition: $condition) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLanguageMutationVariables,
  APITypes.DeleteLanguageMutation
>;
export const createMedia = /* GraphQL */ `mutation CreateMedia(
  $input: CreateMediaInput!
  $condition: ModelMediaConditionInput
) {
  createMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMediaMutationVariables,
  APITypes.CreateMediaMutation
>;
export const updateMedia = /* GraphQL */ `mutation UpdateMedia(
  $input: UpdateMediaInput!
  $condition: ModelMediaConditionInput
) {
  updateMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMediaMutationVariables,
  APITypes.UpdateMediaMutation
>;
export const deleteMedia = /* GraphQL */ `mutation DeleteMedia(
  $input: DeleteMediaInput!
  $condition: ModelMediaConditionInput
) {
  deleteMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMediaMutationVariables,
  APITypes.DeleteMediaMutation
>;
export const createAttachment = /* GraphQL */ `mutation CreateAttachment(
  $input: CreateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  createAttachment(input: $input, condition: $condition) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttachmentMutationVariables,
  APITypes.CreateAttachmentMutation
>;
export const updateAttachment = /* GraphQL */ `mutation UpdateAttachment(
  $input: UpdateAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  updateAttachment(input: $input, condition: $condition) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttachmentMutationVariables,
  APITypes.UpdateAttachmentMutation
>;
export const deleteAttachment = /* GraphQL */ `mutation DeleteAttachment(
  $input: DeleteAttachmentInput!
  $condition: ModelAttachmentConditionInput
) {
  deleteAttachment(input: $input, condition: $condition) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttachmentMutationVariables,
  APITypes.DeleteAttachmentMutation
>;
export const createConcept = /* GraphQL */ `mutation CreateConcept(
  $input: CreateConceptInput!
  $condition: ModelConceptConditionInput
) {
  createConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    email
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    precedence
    isPinRequired
    socialMedia
    operating
    longitude
    latitude
    eventDeposit
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConceptMutationVariables,
  APITypes.CreateConceptMutation
>;
export const updateConcept = /* GraphQL */ `mutation UpdateConcept(
  $input: UpdateConceptInput!
  $condition: ModelConceptConditionInput
) {
  updateConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    email
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    precedence
    isPinRequired
    socialMedia
    operating
    longitude
    latitude
    eventDeposit
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConceptMutationVariables,
  APITypes.UpdateConceptMutation
>;
export const deleteConcept = /* GraphQL */ `mutation DeleteConcept(
  $input: DeleteConceptInput!
  $condition: ModelConceptConditionInput
) {
  deleteConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    email
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    precedence
    isPinRequired
    socialMedia
    operating
    longitude
    latitude
    eventDeposit
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConceptMutationVariables,
  APITypes.DeleteConceptMutation
>;
export const createParentConcept = /* GraphQL */ `mutation CreateParentConcept(
  $input: CreateParentConceptInput!
  $condition: ModelParentConceptConditionInput
) {
  createParentConcept(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParentConceptMutationVariables,
  APITypes.CreateParentConceptMutation
>;
export const updateParentConcept = /* GraphQL */ `mutation UpdateParentConcept(
  $input: UpdateParentConceptInput!
  $condition: ModelParentConceptConditionInput
) {
  updateParentConcept(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParentConceptMutationVariables,
  APITypes.UpdateParentConceptMutation
>;
export const deleteParentConcept = /* GraphQL */ `mutation DeleteParentConcept(
  $input: DeleteParentConceptInput!
  $condition: ModelParentConceptConditionInput
) {
  deleteParentConcept(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues
    index
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteParentConceptMutationVariables,
  APITypes.DeleteParentConceptMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $input: CreateGroupInput!
  $condition: ModelGroupConditionInput
) {
  createGroup(input: $input, condition: $condition) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup(
  $input: UpdateGroupInput!
  $condition: ModelGroupConditionInput
) {
  updateGroup(input: $input, condition: $condition) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup(
  $input: DeleteGroupInput!
  $condition: ModelGroupConditionInput
) {
  deleteGroup(input: $input, condition: $condition) {
    id
    accountID
    name
    description
    color
    requireApproval
    status
    agents
    groupSettings
    numToUpgrade
    numToDowngrade
    upgradeGroup
    downgradeGroup
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const createGroupSetting = /* GraphQL */ `mutation CreateGroupSetting(
  $input: CreateGroupSettingInput!
  $condition: ModelGroupSettingConditionInput
) {
  createGroupSetting(input: $input, condition: $condition) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupSettingMutationVariables,
  APITypes.CreateGroupSettingMutation
>;
export const updateGroupSetting = /* GraphQL */ `mutation UpdateGroupSetting(
  $input: UpdateGroupSettingInput!
  $condition: ModelGroupSettingConditionInput
) {
  updateGroupSetting(input: $input, condition: $condition) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupSettingMutationVariables,
  APITypes.UpdateGroupSettingMutation
>;
export const deleteGroupSetting = /* GraphQL */ `mutation DeleteGroupSetting(
  $input: DeleteGroupSettingInput!
  $condition: ModelGroupSettingConditionInput
) {
  deleteGroupSetting(input: $input, condition: $condition) {
    id
    groupID
    statusID
    needsVerification
    needsDeposit
    isDefault
    amount
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupSettingMutationVariables,
  APITypes.DeleteGroupSettingMutation
>;
export const createCustomerStats = /* GraphQL */ `mutation CreateCustomerStats(
  $input: CreateCustomerStatsInput!
  $condition: ModelCustomerStatsConditionInput
) {
  createCustomerStats(input: $input, condition: $condition) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomerStatsMutationVariables,
  APITypes.CreateCustomerStatsMutation
>;
export const updateCustomerStats = /* GraphQL */ `mutation UpdateCustomerStats(
  $input: UpdateCustomerStatsInput!
  $condition: ModelCustomerStatsConditionInput
) {
  updateCustomerStats(input: $input, condition: $condition) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerStatsMutationVariables,
  APITypes.UpdateCustomerStatsMutation
>;
export const deleteCustomerStats = /* GraphQL */ `mutation DeleteCustomerStats(
  $input: DeleteCustomerStatsInput!
  $condition: ModelCustomerStatsConditionInput
) {
  deleteCustomerStats(input: $input, condition: $condition) {
    id
    conceptID
    totalReservation
    confirmed
    notConfirmed
    canceled
    checkIn
    noShow
    userID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerStatsMutationVariables,
  APITypes.DeleteCustomerStatsMutation
>;
export const createFlag = /* GraphQL */ `mutation CreateFlag(
  $input: CreateFlagInput!
  $condition: ModelFlagConditionInput
) {
  createFlag(input: $input, condition: $condition) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlagMutationVariables,
  APITypes.CreateFlagMutation
>;
export const updateFlag = /* GraphQL */ `mutation UpdateFlag(
  $input: UpdateFlagInput!
  $condition: ModelFlagConditionInput
) {
  updateFlag(input: $input, condition: $condition) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFlagMutationVariables,
  APITypes.UpdateFlagMutation
>;
export const deleteFlag = /* GraphQL */ `mutation DeleteFlag(
  $input: DeleteFlagInput!
  $condition: ModelFlagConditionInput
) {
  deleteFlag(input: $input, condition: $condition) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFlagMutationVariables,
  APITypes.DeleteFlagMutation
>;
export const createInterest = /* GraphQL */ `mutation CreateInterest(
  $input: CreateInterestInput!
  $condition: ModelInterestConditionInput
) {
  createInterest(input: $input, condition: $condition) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInterestMutationVariables,
  APITypes.CreateInterestMutation
>;
export const updateInterest = /* GraphQL */ `mutation UpdateInterest(
  $input: UpdateInterestInput!
  $condition: ModelInterestConditionInput
) {
  updateInterest(input: $input, condition: $condition) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInterestMutationVariables,
  APITypes.UpdateInterestMutation
>;
export const deleteInterest = /* GraphQL */ `mutation DeleteInterest(
  $input: DeleteInterestInput!
  $condition: ModelInterestConditionInput
) {
  deleteInterest(input: $input, condition: $condition) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInterestMutationVariables,
  APITypes.DeleteInterestMutation
>;
export const createReservationStatus = /* GraphQL */ `mutation CreateReservationStatus(
  $input: CreateReservationStatusInput!
  $condition: ModelReservationStatusConditionInput
) {
  createReservationStatus(input: $input, condition: $condition) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReservationStatusMutationVariables,
  APITypes.CreateReservationStatusMutation
>;
export const updateReservationStatus = /* GraphQL */ `mutation UpdateReservationStatus(
  $input: UpdateReservationStatusInput!
  $condition: ModelReservationStatusConditionInput
) {
  updateReservationStatus(input: $input, condition: $condition) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReservationStatusMutationVariables,
  APITypes.UpdateReservationStatusMutation
>;
export const deleteReservationStatus = /* GraphQL */ `mutation DeleteReservationStatus(
  $input: DeleteReservationStatusInput!
  $condition: ModelReservationStatusConditionInput
) {
  deleteReservationStatus(input: $input, condition: $condition) {
    id
    accountID
    name
    category
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    reservationStatusImageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReservationStatusMutationVariables,
  APITypes.DeleteReservationStatusMutation
>;
export const createTimeSlot = /* GraphQL */ `mutation CreateTimeSlot(
  $input: CreateTimeSlotInput!
  $condition: ModelTimeSlotConditionInput
) {
  createTimeSlot(input: $input, condition: $condition) {
    id
    conceptID
    name
    friendlyName
    bookings
    events
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimeSlotMutationVariables,
  APITypes.CreateTimeSlotMutation
>;
export const updateTimeSlot = /* GraphQL */ `mutation UpdateTimeSlot(
  $input: UpdateTimeSlotInput!
  $condition: ModelTimeSlotConditionInput
) {
  updateTimeSlot(input: $input, condition: $condition) {
    id
    conceptID
    name
    friendlyName
    bookings
    events
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimeSlotMutationVariables,
  APITypes.UpdateTimeSlotMutation
>;
export const deleteTimeSlot = /* GraphQL */ `mutation DeleteTimeSlot(
  $input: DeleteTimeSlotInput!
  $condition: ModelTimeSlotConditionInput
) {
  deleteTimeSlot(input: $input, condition: $condition) {
    id
    conceptID
    name
    friendlyName
    bookings
    events
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimeSlotMutationVariables,
  APITypes.DeleteTimeSlotMutation
>;
export const createArea = /* GraphQL */ `mutation CreateArea(
  $input: CreateAreaInput!
  $condition: ModelAreaConditionInput
) {
  createArea(input: $input, condition: $condition) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAreaMutationVariables,
  APITypes.CreateAreaMutation
>;
export const updateArea = /* GraphQL */ `mutation UpdateArea(
  $input: UpdateAreaInput!
  $condition: ModelAreaConditionInput
) {
  updateArea(input: $input, condition: $condition) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAreaMutationVariables,
  APITypes.UpdateAreaMutation
>;
export const deleteArea = /* GraphQL */ `mutation DeleteArea(
  $input: DeleteAreaInput!
  $condition: ModelAreaConditionInput
) {
  deleteArea(input: $input, condition: $condition) {
    id
    conceptID
    name
    isDefault
    expiryDate
    days
    timeSlots
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAreaMutationVariables,
  APITypes.DeleteAreaMutation
>;
export const createPlanItem = /* GraphQL */ `mutation CreatePlanItem(
  $input: CreatePlanItemInput!
  $condition: ModelPlanItemConditionInput
) {
  createPlanItem(input: $input, condition: $condition) {
    id
    areaID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlanItemMutationVariables,
  APITypes.CreatePlanItemMutation
>;
export const updatePlanItem = /* GraphQL */ `mutation UpdatePlanItem(
  $input: UpdatePlanItemInput!
  $condition: ModelPlanItemConditionInput
) {
  updatePlanItem(input: $input, condition: $condition) {
    id
    areaID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlanItemMutationVariables,
  APITypes.UpdatePlanItemMutation
>;
export const deletePlanItem = /* GraphQL */ `mutation DeletePlanItem(
  $input: DeletePlanItemInput!
  $condition: ModelPlanItemConditionInput
) {
  deletePlanItem(input: $input, condition: $condition) {
    id
    areaID
    xPosition
    yPosition
    name
    isReserved
    bookings
    table
    object
    lockDays
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlanItemMutationVariables,
  APITypes.DeletePlanItemMutation
>;
export const createTable = /* GraphQL */ `mutation CreateTable(
  $input: CreateTableInput!
  $condition: ModelTableConditionInput
) {
  createTable(input: $input, condition: $condition) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTableMutationVariables,
  APITypes.CreateTableMutation
>;
export const updateTable = /* GraphQL */ `mutation UpdateTable(
  $input: UpdateTableInput!
  $condition: ModelTableConditionInput
) {
  updateTable(input: $input, condition: $condition) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTableMutationVariables,
  APITypes.UpdateTableMutation
>;
export const deleteTable = /* GraphQL */ `mutation DeleteTable(
  $input: DeleteTableInput!
  $condition: ModelTableConditionInput
) {
  deleteTable(input: $input, condition: $condition) {
    id
    conceptID
    label
    name
    width
    height
    capacity
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTableMutationVariables,
  APITypes.DeleteTableMutation
>;
export const createObject = /* GraphQL */ `mutation CreateObject(
  $input: CreateObjectInput!
  $condition: ModelObjectConditionInput
) {
  createObject(input: $input, condition: $condition) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateObjectMutationVariables,
  APITypes.CreateObjectMutation
>;
export const updateObject = /* GraphQL */ `mutation UpdateObject(
  $input: UpdateObjectInput!
  $condition: ModelObjectConditionInput
) {
  updateObject(input: $input, condition: $condition) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateObjectMutationVariables,
  APITypes.UpdateObjectMutation
>;
export const deleteObject = /* GraphQL */ `mutation DeleteObject(
  $input: DeleteObjectInput!
  $condition: ModelObjectConditionInput
) {
  deleteObject(input: $input, condition: $condition) {
    id
    conceptID
    label
    name
    image {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    width
    height
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    objectImageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteObjectMutationVariables,
  APITypes.DeleteObjectMutation
>;
export const createFriend = /* GraphQL */ `mutation CreateFriend(
  $input: CreateFriendInput!
  $condition: ModelFriendConditionInput
) {
  createFriend(input: $input, condition: $condition) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFriendMutationVariables,
  APITypes.CreateFriendMutation
>;
export const updateFriend = /* GraphQL */ `mutation UpdateFriend(
  $input: UpdateFriendInput!
  $condition: ModelFriendConditionInput
) {
  updateFriend(input: $input, condition: $condition) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFriendMutationVariables,
  APITypes.UpdateFriendMutation
>;
export const deleteFriend = /* GraphQL */ `mutation DeleteFriend(
  $input: DeleteFriendInput!
  $condition: ModelFriendConditionInput
) {
  deleteFriend(input: $input, condition: $condition) {
    id
    customerId
    friendId
    bookingID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFriendMutationVariables,
  APITypes.DeleteFriendMutation
>;
export const createBooking = /* GraphQL */ `mutation CreateBooking(
  $input: CreateBookingInput!
  $condition: ModelBookingConditionInput
) {
  createBooking(input: $input, condition: $condition) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    customerGroupColor
    customerGroupID
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingMutationVariables,
  APITypes.CreateBookingMutation
>;
export const updateBooking = /* GraphQL */ `mutation UpdateBooking(
  $input: UpdateBookingInput!
  $condition: ModelBookingConditionInput
) {
  updateBooking(input: $input, condition: $condition) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    customerGroupColor
    customerGroupID
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingMutationVariables,
  APITypes.UpdateBookingMutation
>;
export const deleteBooking = /* GraphQL */ `mutation DeleteBooking(
  $input: DeleteBookingInput!
  $condition: ModelBookingConditionInput
) {
  deleteBooking(input: $input, condition: $condition) {
    id
    depositValue
    depositStatus
    eventStatus
    eventName
    paymentStatus
    paymentValue
    disablePartialPayment
    disablePayment
    eventDate
    eventGuests
    timeSlotID
    timeSlots
    conceptID
    mainGuest
    customerName
    customerPhone
    customerGroup
    customerGroupColor
    customerGroupID
    accompaniedCount
    channel
    statusID
    rating
    review
    confirmationHash
    isConfirmedByUser
    date
    tables
    tableName
    comments
    lastComment
    commentCreatedBy
    commentCreatedDate
    timeLeft
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookingMutationVariables,
  APITypes.DeleteBookingMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    message
    customerId
    bookingId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createTimeline = /* GraphQL */ `mutation CreateTimeline(
  $input: CreateTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  createTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimelineMutationVariables,
  APITypes.CreateTimelineMutation
>;
export const updateTimeline = /* GraphQL */ `mutation UpdateTimeline(
  $input: UpdateTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  updateTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimelineMutationVariables,
  APITypes.UpdateTimelineMutation
>;
export const deleteTimeline = /* GraphQL */ `mutation DeleteTimeline(
  $input: DeleteTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  deleteTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimelineMutationVariables,
  APITypes.DeleteTimelineMutation
>;
export const createServingArea = /* GraphQL */ `mutation CreateServingArea(
  $input: CreateServingAreaInput!
  $condition: ModelServingAreaConditionInput
) {
  createServingArea(input: $input, condition: $condition) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServingAreaMutationVariables,
  APITypes.CreateServingAreaMutation
>;
export const updateServingArea = /* GraphQL */ `mutation UpdateServingArea(
  $input: UpdateServingAreaInput!
  $condition: ModelServingAreaConditionInput
) {
  updateServingArea(input: $input, condition: $condition) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServingAreaMutationVariables,
  APITypes.UpdateServingAreaMutation
>;
export const deleteServingArea = /* GraphQL */ `mutation DeleteServingArea(
  $input: DeleteServingAreaInput!
  $condition: ModelServingAreaConditionInput
) {
  deleteServingArea(input: $input, condition: $condition) {
    id
    name
    fees
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServingAreaMutationVariables,
  APITypes.DeleteServingAreaMutation
>;
export const createCall = /* GraphQL */ `mutation CreateCall(
  $input: CreateCallInput!
  $condition: ModelCallConditionInput
) {
  createCall(input: $input, condition: $condition) {
    id
    phone_number
    customer
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCallMutationVariables,
  APITypes.CreateCallMutation
>;
export const updateCall = /* GraphQL */ `mutation UpdateCall(
  $input: UpdateCallInput!
  $condition: ModelCallConditionInput
) {
  updateCall(input: $input, condition: $condition) {
    id
    phone_number
    customer
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCallMutationVariables,
  APITypes.UpdateCallMutation
>;
export const deleteCall = /* GraphQL */ `mutation DeleteCall(
  $input: DeleteCallInput!
  $condition: ModelCallConditionInput
) {
  deleteCall(input: $input, condition: $condition) {
    id
    phone_number
    customer
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCallMutationVariables,
  APITypes.DeleteCallMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    name
    description
    channel
    message
    from
    enabled
    autoSend
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createAdminRole = /* GraphQL */ `mutation CreateAdminRole(
  $input: CreateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  createAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminRoleMutationVariables,
  APITypes.CreateAdminRoleMutation
>;
export const updateAdminRole = /* GraphQL */ `mutation UpdateAdminRole(
  $input: UpdateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  updateAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminRoleMutationVariables,
  APITypes.UpdateAdminRoleMutation
>;
export const deleteAdminRole = /* GraphQL */ `mutation DeleteAdminRole(
  $input: DeleteAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  deleteAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminRoleMutationVariables,
  APITypes.DeleteAdminRoleMutation
>;
export const createAdminGroup = /* GraphQL */ `mutation CreateAdminGroup(
  $input: CreateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  createAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminGroupMutationVariables,
  APITypes.CreateAdminGroupMutation
>;
export const updateAdminGroup = /* GraphQL */ `mutation UpdateAdminGroup(
  $input: UpdateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  updateAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminGroupMutationVariables,
  APITypes.UpdateAdminGroupMutation
>;
export const deleteAdminGroup = /* GraphQL */ `mutation DeleteAdminGroup(
  $input: DeleteAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  deleteAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminGroupMutationVariables,
  APITypes.DeleteAdminGroupMutation
>;
export const createUserConcepts = /* GraphQL */ `mutation CreateUserConcepts(
  $input: CreateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  createUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserConceptsMutationVariables,
  APITypes.CreateUserConceptsMutation
>;
export const updateUserConcepts = /* GraphQL */ `mutation UpdateUserConcepts(
  $input: UpdateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  updateUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserConceptsMutationVariables,
  APITypes.UpdateUserConceptsMutation
>;
export const deleteUserConcepts = /* GraphQL */ `mutation DeleteUserConcepts(
  $input: DeleteUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  deleteUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserConceptsMutationVariables,
  APITypes.DeleteUserConceptsMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $input: CreateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  createTransaction(input: $input, condition: $condition) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction(
  $input: UpdateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  updateTransaction(input: $input, condition: $condition) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionMutationVariables,
  APITypes.UpdateTransactionMutation
>;
export const deleteTransaction = /* GraphQL */ `mutation DeleteTransaction(
  $input: DeleteTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  deleteTransaction(input: $input, condition: $condition) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionMutationVariables,
  APITypes.DeleteTransactionMutation
>;
export const createPaymentStat = /* GraphQL */ `mutation CreatePaymentStat(
  $input: CreatePaymentStatInput!
  $condition: ModelPaymentStatConditionInput
) {
  createPaymentStat(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentStatMutationVariables,
  APITypes.CreatePaymentStatMutation
>;
export const updatePaymentStat = /* GraphQL */ `mutation UpdatePaymentStat(
  $input: UpdatePaymentStatInput!
  $condition: ModelPaymentStatConditionInput
) {
  updatePaymentStat(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentStatMutationVariables,
  APITypes.UpdatePaymentStatMutation
>;
export const deletePaymentStat = /* GraphQL */ `mutation DeletePaymentStat(
  $input: DeletePaymentStatInput!
  $condition: ModelPaymentStatConditionInput
) {
  deletePaymentStat(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentStatMutationVariables,
  APITypes.DeletePaymentStatMutation
>;
export const createBookingGuest = /* GraphQL */ `mutation CreateBookingGuest(
  $input: CreateBookingGuestInput!
  $condition: ModelBookingGuestConditionInput
) {
  createBookingGuest(input: $input, condition: $condition) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingGuestMutationVariables,
  APITypes.CreateBookingGuestMutation
>;
export const updateBookingGuest = /* GraphQL */ `mutation UpdateBookingGuest(
  $input: UpdateBookingGuestInput!
  $condition: ModelBookingGuestConditionInput
) {
  updateBookingGuest(input: $input, condition: $condition) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingGuestMutationVariables,
  APITypes.UpdateBookingGuestMutation
>;
export const deleteBookingGuest = /* GraphQL */ `mutation DeleteBookingGuest(
  $input: DeleteBookingGuestInput!
  $condition: ModelBookingGuestConditionInput
) {
  deleteBookingGuest(input: $input, condition: $condition) {
    id
    bookingID
    guestName
    paidAmount
    paymentAmount
    paymentStatus
    transactionID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookingGuestMutationVariables,
  APITypes.DeleteBookingGuestMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    conceptID
    preparationAreaID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    conceptID
    preparationAreaID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    conceptID
    preparationAreaID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createMenuItem = /* GraphQL */ `mutation CreateMenuItem(
  $input: CreateMenuItemInput!
  $condition: ModelMenuItemConditionInput
) {
  createMenuItem(input: $input, condition: $condition) {
    id
    conceptID
    kioskID
    name
    description
    image
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    ratings
    prices
    choiceGroups
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenuItemMutationVariables,
  APITypes.CreateMenuItemMutation
>;
export const updateMenuItem = /* GraphQL */ `mutation UpdateMenuItem(
  $input: UpdateMenuItemInput!
  $condition: ModelMenuItemConditionInput
) {
  updateMenuItem(input: $input, condition: $condition) {
    id
    conceptID
    kioskID
    name
    description
    image
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    ratings
    prices
    choiceGroups
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMenuItemMutationVariables,
  APITypes.UpdateMenuItemMutation
>;
export const deleteMenuItem = /* GraphQL */ `mutation DeleteMenuItem(
  $input: DeleteMenuItemInput!
  $condition: ModelMenuItemConditionInput
) {
  deleteMenuItem(input: $input, condition: $condition) {
    id
    conceptID
    kioskID
    name
    description
    image
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    ratings
    prices
    choiceGroups
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMenuItemMutationVariables,
  APITypes.DeleteMenuItemMutation
>;
export const createPrice = /* GraphQL */ `mutation CreatePrice(
  $input: CreatePriceInput!
  $condition: ModelPriceConditionInput
) {
  createPrice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePriceMutationVariables,
  APITypes.CreatePriceMutation
>;
export const updatePrice = /* GraphQL */ `mutation UpdatePrice(
  $input: UpdatePriceInput!
  $condition: ModelPriceConditionInput
) {
  updatePrice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePriceMutationVariables,
  APITypes.UpdatePriceMutation
>;
export const deletePrice = /* GraphQL */ `mutation DeletePrice(
  $input: DeletePriceInput!
  $condition: ModelPriceConditionInput
) {
  deletePrice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePriceMutationVariables,
  APITypes.DeletePriceMutation
>;
export const createChoiceGroups = /* GraphQL */ `mutation CreateChoiceGroups(
  $input: CreateChoiceGroupsInput!
  $condition: ModelChoiceGroupsConditionInput
) {
  createChoiceGroups(input: $input, condition: $condition) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChoiceGroupsMutationVariables,
  APITypes.CreateChoiceGroupsMutation
>;
export const updateChoiceGroups = /* GraphQL */ `mutation UpdateChoiceGroups(
  $input: UpdateChoiceGroupsInput!
  $condition: ModelChoiceGroupsConditionInput
) {
  updateChoiceGroups(input: $input, condition: $condition) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChoiceGroupsMutationVariables,
  APITypes.UpdateChoiceGroupsMutation
>;
export const deleteChoiceGroups = /* GraphQL */ `mutation DeleteChoiceGroups(
  $input: DeleteChoiceGroupsInput!
  $condition: ModelChoiceGroupsConditionInput
) {
  deleteChoiceGroups(input: $input, condition: $condition) {
    id
    conceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChoiceGroupsMutationVariables,
  APITypes.DeleteChoiceGroupsMutation
>;
export const createChoice = /* GraphQL */ `mutation CreateChoice(
  $input: CreateChoiceInput!
  $condition: ModelChoiceConditionInput
) {
  createChoice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChoiceMutationVariables,
  APITypes.CreateChoiceMutation
>;
export const updateChoice = /* GraphQL */ `mutation UpdateChoice(
  $input: UpdateChoiceInput!
  $condition: ModelChoiceConditionInput
) {
  updateChoice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChoiceMutationVariables,
  APITypes.UpdateChoiceMutation
>;
export const deleteChoice = /* GraphQL */ `mutation DeleteChoice(
  $input: DeleteChoiceInput!
  $condition: ModelChoiceConditionInput
) {
  deleteChoice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChoiceMutationVariables,
  APITypes.DeleteChoiceMutation
>;
export const createCart = /* GraphQL */ `mutation CreateCart(
  $input: CreateCartInput!
  $condition: ModelCartConditionInput
) {
  createCart(input: $input, condition: $condition) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    specialRequest
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCartMutationVariables,
  APITypes.CreateCartMutation
>;
export const updateCart = /* GraphQL */ `mutation UpdateCart(
  $input: UpdateCartInput!
  $condition: ModelCartConditionInput
) {
  updateCart(input: $input, condition: $condition) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    specialRequest
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCartMutationVariables,
  APITypes.UpdateCartMutation
>;
export const deleteCart = /* GraphQL */ `mutation DeleteCart(
  $input: DeleteCartInput!
  $condition: ModelCartConditionInput
) {
  deleteCart(input: $input, condition: $condition) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    specialRequest
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCartMutationVariables,
  APITypes.DeleteCartMutation
>;
export const createAdminStatus = /* GraphQL */ `mutation CreateAdminStatus(
  $input: CreateAdminStatusInput!
  $condition: ModelAdminStatusConditionInput
) {
  createAdminStatus(input: $input, condition: $condition) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminStatusMutationVariables,
  APITypes.CreateAdminStatusMutation
>;
export const updateAdminStatus = /* GraphQL */ `mutation UpdateAdminStatus(
  $input: UpdateAdminStatusInput!
  $condition: ModelAdminStatusConditionInput
) {
  updateAdminStatus(input: $input, condition: $condition) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminStatusMutationVariables,
  APITypes.UpdateAdminStatusMutation
>;
export const deleteAdminStatus = /* GraphQL */ `mutation DeleteAdminStatus(
  $input: DeleteAdminStatusInput!
  $condition: ModelAdminStatusConditionInput
) {
  deleteAdminStatus(input: $input, condition: $condition) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminStatusMutationVariables,
  APITypes.DeleteAdminStatusMutation
>;
export const createOnlineOrder = /* GraphQL */ `mutation CreateOnlineOrder(
  $input: CreateOnlineOrderInput!
  $condition: ModelOnlineOrderConditionInput
) {
  createOnlineOrder(input: $input, condition: $condition) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    userID
    userName
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    deliveryFee
    orderTime
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    waiterID
    tableID
    shift
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    deliveredAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOnlineOrderMutationVariables,
  APITypes.CreateOnlineOrderMutation
>;
export const updateOnlineOrder = /* GraphQL */ `mutation UpdateOnlineOrder(
  $input: UpdateOnlineOrderInput!
  $condition: ModelOnlineOrderConditionInput
) {
  updateOnlineOrder(input: $input, condition: $condition) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    userID
    userName
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    deliveryFee
    orderTime
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    waiterID
    tableID
    shift
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    deliveredAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOnlineOrderMutationVariables,
  APITypes.UpdateOnlineOrderMutation
>;
export const deleteOnlineOrder = /* GraphQL */ `mutation DeleteOnlineOrder(
  $input: DeleteOnlineOrderInput!
  $condition: ModelOnlineOrderConditionInput
) {
  deleteOnlineOrder(input: $input, condition: $condition) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    userID
    userName
    dispatcherID
    dispatcherName
    cartID
    tenderId
    employeeId
    rvcId
    checkNumber
    totalAmount
    deliveryFee
    orderTime
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      symphonyID
      preparationAreaID
      name
      quantity
      choiceIDs
      choiceSymphonyID
      extraChoiceIDs
      extraChoiceSymphonyID
      price
      priceName
      notes
      image
      __typename
    }
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    waiterID
    tableID
    shift
    createdAt
    acceptedByWaiterAt
    sentToKitchenAt
    acceptedByKitchenAt
    readyAt
    deliveredAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOnlineOrderMutationVariables,
  APITypes.DeleteOnlineOrderMutation
>;
export const createOrderStatus = /* GraphQL */ `mutation CreateOrderStatus(
  $input: CreateOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  createOrderStatus(input: $input, condition: $condition) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderStatusMutationVariables,
  APITypes.CreateOrderStatusMutation
>;
export const updateOrderStatus = /* GraphQL */ `mutation UpdateOrderStatus(
  $input: UpdateOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  updateOrderStatus(input: $input, condition: $condition) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderStatusMutationVariables,
  APITypes.UpdateOrderStatusMutation
>;
export const deleteOrderStatus = /* GraphQL */ `mutation DeleteOrderStatus(
  $input: DeleteOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  deleteOrderStatus(input: $input, condition: $condition) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderStatusMutationVariables,
  APITypes.DeleteOrderStatusMutation
>;
export const createDiscountCoupon = /* GraphQL */ `mutation CreateDiscountCoupon(
  $input: CreateDiscountCouponInput!
  $condition: ModelDiscountCouponConditionInput
) {
  createDiscountCoupon(input: $input, condition: $condition) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscountCouponMutationVariables,
  APITypes.CreateDiscountCouponMutation
>;
export const updateDiscountCoupon = /* GraphQL */ `mutation UpdateDiscountCoupon(
  $input: UpdateDiscountCouponInput!
  $condition: ModelDiscountCouponConditionInput
) {
  updateDiscountCoupon(input: $input, condition: $condition) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiscountCouponMutationVariables,
  APITypes.UpdateDiscountCouponMutation
>;
export const deleteDiscountCoupon = /* GraphQL */ `mutation DeleteDiscountCoupon(
  $input: DeleteDiscountCouponInput!
  $condition: ModelDiscountCouponConditionInput
) {
  deleteDiscountCoupon(input: $input, condition: $condition) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiscountCouponMutationVariables,
  APITypes.DeleteDiscountCouponMutation
>;
export const createMobileNotification = /* GraphQL */ `mutation CreateMobileNotification(
  $input: CreateMobileNotificationInput!
  $condition: ModelMobileNotificationConditionInput
) {
  createMobileNotification(input: $input, condition: $condition) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    eventDate
    conceptID
    depositValue
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMobileNotificationMutationVariables,
  APITypes.CreateMobileNotificationMutation
>;
export const updateMobileNotification = /* GraphQL */ `mutation UpdateMobileNotification(
  $input: UpdateMobileNotificationInput!
  $condition: ModelMobileNotificationConditionInput
) {
  updateMobileNotification(input: $input, condition: $condition) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    eventDate
    conceptID
    depositValue
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMobileNotificationMutationVariables,
  APITypes.UpdateMobileNotificationMutation
>;
export const deleteMobileNotification = /* GraphQL */ `mutation DeleteMobileNotification(
  $input: DeleteMobileNotificationInput!
  $condition: ModelMobileNotificationConditionInput
) {
  deleteMobileNotification(input: $input, condition: $condition) {
    id
    title
    description
    body
    default
    event
    image
    concepts
    condition
    startDate
    endDate
    group
    read
    eventDate
    conceptID
    depositValue
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMobileNotificationMutationVariables,
  APITypes.DeleteMobileNotificationMutation
>;
export const createUserPushToken = /* GraphQL */ `mutation CreateUserPushToken(
  $input: CreateUserPushTokenInput!
  $condition: ModelUserPushTokenConditionInput
) {
  createUserPushToken(input: $input, condition: $condition) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPushTokenMutationVariables,
  APITypes.CreateUserPushTokenMutation
>;
export const updateUserPushToken = /* GraphQL */ `mutation UpdateUserPushToken(
  $input: UpdateUserPushTokenInput!
  $condition: ModelUserPushTokenConditionInput
) {
  updateUserPushToken(input: $input, condition: $condition) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPushTokenMutationVariables,
  APITypes.UpdateUserPushTokenMutation
>;
export const deleteUserPushToken = /* GraphQL */ `mutation DeleteUserPushToken(
  $input: DeleteUserPushTokenInput!
  $condition: ModelUserPushTokenConditionInput
) {
  deleteUserPushToken(input: $input, condition: $condition) {
    id
    userID
    token
    reciveNotification
    type
    os
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPushTokenMutationVariables,
  APITypes.DeleteUserPushTokenMutation
>;
export const createNotificationReceiver = /* GraphQL */ `mutation CreateNotificationReceiver(
  $input: CreateNotificationReceiverInput!
  $condition: ModelNotificationReceiverConditionInput
) {
  createNotificationReceiver(input: $input, condition: $condition) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationReceiverMutationVariables,
  APITypes.CreateNotificationReceiverMutation
>;
export const updateNotificationReceiver = /* GraphQL */ `mutation UpdateNotificationReceiver(
  $input: UpdateNotificationReceiverInput!
  $condition: ModelNotificationReceiverConditionInput
) {
  updateNotificationReceiver(input: $input, condition: $condition) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationReceiverMutationVariables,
  APITypes.UpdateNotificationReceiverMutation
>;
export const deleteNotificationReceiver = /* GraphQL */ `mutation DeleteNotificationReceiver(
  $input: DeleteNotificationReceiverInput!
  $condition: ModelNotificationReceiverConditionInput
) {
  deleteNotificationReceiver(input: $input, condition: $condition) {
    id
    userID
    notificationID
    seen
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationReceiverMutationVariables,
  APITypes.DeleteNotificationReceiverMutation
>;
export const createZone = /* GraphQL */ `mutation CreateZone(
  $input: CreateZoneInput!
  $condition: ModelZoneConditionInput
) {
  createZone(input: $input, condition: $condition) {
    id
    conceptID
    location
    deliveryFee
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateZoneMutationVariables,
  APITypes.CreateZoneMutation
>;
export const updateZone = /* GraphQL */ `mutation UpdateZone(
  $input: UpdateZoneInput!
  $condition: ModelZoneConditionInput
) {
  updateZone(input: $input, condition: $condition) {
    id
    conceptID
    location
    deliveryFee
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZoneMutationVariables,
  APITypes.UpdateZoneMutation
>;
export const deleteZone = /* GraphQL */ `mutation DeleteZone(
  $input: DeleteZoneInput!
  $condition: ModelZoneConditionInput
) {
  deleteZone(input: $input, condition: $condition) {
    id
    conceptID
    location
    deliveryFee
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteZoneMutationVariables,
  APITypes.DeleteZoneMutation
>;
export const createUserIP = /* GraphQL */ `mutation CreateUserIP(
  $input: CreateUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  createUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserIPMutationVariables,
  APITypes.CreateUserIPMutation
>;
export const updateUserIP = /* GraphQL */ `mutation UpdateUserIP(
  $input: UpdateUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  updateUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserIPMutationVariables,
  APITypes.UpdateUserIPMutation
>;
export const deleteUserIP = /* GraphQL */ `mutation DeleteUserIP(
  $input: DeleteUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  deleteUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserIPMutationVariables,
  APITypes.DeleteUserIPMutation
>;
export const createMainCategory = /* GraphQL */ `mutation CreateMainCategory(
  $input: CreateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  createMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMainCategoryMutationVariables,
  APITypes.CreateMainCategoryMutation
>;
export const updateMainCategory = /* GraphQL */ `mutation UpdateMainCategory(
  $input: UpdateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  updateMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMainCategoryMutationVariables,
  APITypes.UpdateMainCategoryMutation
>;
export const deleteMainCategory = /* GraphQL */ `mutation DeleteMainCategory(
  $input: DeleteMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  deleteMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    image
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMainCategoryMutationVariables,
  APITypes.DeleteMainCategoryMutation
>;
export const createShift = /* GraphQL */ `mutation CreateShift(
  $input: CreateShiftInput!
  $condition: ModelShiftConditionInput
) {
  createShift(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    startTime
    endTime
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShiftMutationVariables,
  APITypes.CreateShiftMutation
>;
export const updateShift = /* GraphQL */ `mutation UpdateShift(
  $input: UpdateShiftInput!
  $condition: ModelShiftConditionInput
) {
  updateShift(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    startTime
    endTime
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShiftMutationVariables,
  APITypes.UpdateShiftMutation
>;
export const deleteShift = /* GraphQL */ `mutation DeleteShift(
  $input: DeleteShiftInput!
  $condition: ModelShiftConditionInput
) {
  deleteShift(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    startTime
    endTime
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShiftMutationVariables,
  APITypes.DeleteShiftMutation
>;
export const createPreparationArea = /* GraphQL */ `mutation CreatePreparationArea(
  $input: CreatePreparationAreaInput!
  $condition: ModelPreparationAreaConditionInput
) {
  createPreparationArea(input: $input, condition: $condition) {
    id
    conceptID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePreparationAreaMutationVariables,
  APITypes.CreatePreparationAreaMutation
>;
export const updatePreparationArea = /* GraphQL */ `mutation UpdatePreparationArea(
  $input: UpdatePreparationAreaInput!
  $condition: ModelPreparationAreaConditionInput
) {
  updatePreparationArea(input: $input, condition: $condition) {
    id
    conceptID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreparationAreaMutationVariables,
  APITypes.UpdatePreparationAreaMutation
>;
export const deletePreparationArea = /* GraphQL */ `mutation DeletePreparationArea(
  $input: DeletePreparationAreaInput!
  $condition: ModelPreparationAreaConditionInput
) {
  deletePreparationArea(input: $input, condition: $condition) {
    id
    conceptID
    name
    isAutoAccept
    hasKDS
    hasPrinter
    users
    deleted
    precedence
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePreparationAreaMutationVariables,
  APITypes.DeletePreparationAreaMutation
>;
